<template>
  <v-card flat max-width="550px">
    <v-card-title>
      Settings
    </v-card-title>
    <v-list v-if="project">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Project Identifier:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ project.id }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <template v-if="editing !== 'name'">
          <v-list-item-content class="mb-2">
            <v-list-item-title>
              Project Name:
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ project.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!editing"
              icon
              @click="editing = 'name'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
        <validation-provider
          v-else
          v-slot="{ errors }"
          rules="required|min:5"
          name="Name"
          class="flex-grow-1"
        >
          <v-text-field
            v-model="form.name"
            label="Project Name"
            type="text"
            outlined
            hide-details
            :error-messages="errors"
            required
            class="mb-0 pr-2"
            append-outer-icon="mdi-check"
            :loading="saving === 'name'"
            @click:append-outer="save('name')"
            @keypress.enter="save('name')"
          />
        </validation-provider>
      </v-list-item>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            Clean project related data
          </v-list-item-title>
          <v-list-item-subtitle>
            Deletes all schemas and history entries from your fireful setup.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            outlined
            color="error"
            :disabled="!!loading"
            :loading="loading === 'cleaning'"
            @click="cleanProject"
          >
            <v-icon left>
              mdi-rewind
            </v-icon>
            Clean
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            Delete project
          </v-list-item-title>
          <v-list-item-subtitle>
            Removes {{ project.name }} from fireful and deletes all data.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            outlined
            color="error"
            :disabled="!!loading"
            :loading="loading === 'deleting'"
            @click="deleteProject"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, functions } from '@/plugins/firebase'

export default {
  name: 'ProjectSettings',
  data: () => ({
    loading: false,
    editing: false,
    saving: false,
    form: {
      name: ''
    }
  }),
  computed: {
    ...mapGetters(['project'])
  },
  watch: {
    project: {
      deep: true,
      immediate: true,
      handler(project) {
        this.form.name = project
          ? project.name
          : ''
      }
    }
  },
  methods: {
    async save(field) {
      this.saving = field
      if (this.project[field] !== this.form[field]) {
        await db.collection('projects').doc(this.project.id).update({
          [field]: this.form[field]
        })
      }
      this.saving = false
      this.editing = false
    },
    cleanProject() {
      if (!confirm('Are you sure? This will keep your project linked to fireful, but data related to collection schemas, document audits, storage folder structures and team member data will be wiped from our database. There is no going back!')) {
        return
      }
      this.loading = 'cleaning'
      const cleanProject = functions.httpsCallable('cleanProject')
      cleanProject({ projectId: this.project.id })
        .then(() => {
          this.$store.dispatch('snackbar/new', { type: 'success', message: 'Project successfully cleaned' })
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/new', { error })
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteProject() {
      if (!confirm('Are you sure? Your firebase project data along with collection schemas, document audits, storage folder structures and team member data will be wiped from our database. There is no going back!')) {
        return
      }
      this.loading = 'deleting'
      const deleteProject = functions.httpsCallable('deleteProject')
      deleteProject({ projectId: this.project.id })
        .then(() => {
          this.$store.dispatch('snackbar/new', { type: 'success', message: 'Project successfully deleted' })
          this.$router.push('/')
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/new', { error })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
